import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SummernoteConfigService {
  constructor() { }

  public getDefaultConfig(padding: number): any {
    return {
      height: this.getDefaultHeight(padding),
      toolbar: this.getDefaultToolbar()
    }
  }

  getMinimalConfig(height: number): any {
    return {
      height: height,
      toolbar: this.getMinimalToolbar()
    }
  }

  public getDefaultToolbar(): (string | string[])[][] {
    return [
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
      ['customButtons', ['testBtn', 'testDrop']],
      ['misc2', ['codeview']]
    ];
  }

  public getMinimalToolbar(): (string | string[])[][] {
    return [
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['misc2', ['codeview']]
    ];
  }

  public getDefaultHeight(padding: number): string {
    const pageHeight = window.innerHeight;
    return (pageHeight - padding) + "px";
  }
}
